<template>
  <div>
    <b-row>
      <b-col class="mt-4">
        <b-table
          striped
          responsive
          hover
          :items="items"
          :fields="fields"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
          thead-class="text-nowrap"
          tbody-class="text-nowrap"
        >
          <template v-slot:cell(timeStamp)="data">
            <p class="mb-0">
              {{ data.item.timeStamp | moment($formatDateTime) }}
            </p>
          </template>

          <!-- <template v-slot:cell(target)="data">
                <div v-if="data.item.target">
                  <p class="mb-0">{{ data.item.target }}</p>
                </div>
                <p class="mb-0" v-else>-</p>
              </template> -->

          <template v-slot:cell(detail)="data">
            <b-button variant="link" class="px-1 py-0">
              <router-link :to="'/audit/details/' + data.item.id">
                <font-awesome-icon icon="search" title="View" />
              </router-link>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        class="form-inline justify-content-center justify-content-md-between"
      >
        <b-row>
          <b-col>
            <div class="d-inline-block layout-paging">
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.pageSize"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <div class="d-inline-block ml-md-3 layout-paging">
              <p class="total-record-paging text-nowrap text-center">
                {{ totalRowMessage }}
              </p>
            </div>
          </b-col>
        </b-row>
        <b-form-select
          v-model="filter.pageSize"
          @change="hanndleChangePerpage"
          :options="pageOptions"
        ></b-form-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    filter: {
      required: true,
      type: Object,
    },
    rows: {
      required: true,
      type: Number,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    totalRowMessage: {
      required: false,
      type: String,
    },
  },
  methods: {
    pagination(page) {
      this.$emit("handleChangePage",page);
    },
    hanndleChangePerpage(value) {
      this.$emit("changePerPage",value);
    },
  },
};
</script>

<style></style>

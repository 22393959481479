<template>
  <div>
    <div class="parent-cat">
      <b-form-checkbox size="lg" class="mt-2" v-model="isAll" @change="handleSelectAll">
        <span>All</span>
      </b-form-checkbox>
    </div>
    <div v-if="menuSelectedList">
      <template v-for="(item2, key2) in items">
        <div :key="`lv2-${key2}`">
          <div class="text-left my-3">
            <div class="pl-lv2">
              <div class="parent-cat">
                <b-form-checkbox
                  size="lg"
                  :value="item2.id"
                  v-model="menuSelectedList"
                  @change="addRootMenu(item2.subMenu,item2.id,key2,item2.subMenu)"
                >
                  <span>{{item2.name}}</span>
                  <span class="text-secondary pl-2">{{item2.description}}</span>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <template v-for="(item3, key3) in item2.subMenu">
          <div :key="`lv2-${key2}-lv3-${key3}`">
            <div class="text-left my-3 pl-lv3">
              <div class="ml-4">
                <div class="parent-cat">
                  <b-form-checkbox
                    size="lg"
                    class="mt-1 ml-1"
                    v-bind:key="key3"
                    :value="item3.id"
                    v-model="menuSelectedList"
                    @change="addSubMenu(item2.subMenu,item3.id,key3,item2.id,key2,item3.subMenu)"
                  >
                    <span>{{item3.name}}</span>
                    <span class="text-secondary pl-2">{{item3.description}}</span>
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <template v-for="(item4, key4) in item3.subMenu">
            <div :key="`lv2-${key2}-lv3-${key3}-lv4-${key4}`">
              <div class="text-left my-3 pl-lv4">
                <b-form-checkbox
                  size="lg"
                  class="pl-lv4-cb"
                  v-bind:key="key4"
                  :value="item4.id"
                  v-model="menuSelectedList"
                  @change="addSubMenuLevel2(item2.id,item3.id,item4.id,key2,key3,item3.subMenu,item2.subMenu,items)"
                >
                  <span>{{item4.name}}</span>
                  <span class="text-secondary pl-2">{{item4.description}}</span>
                </b-form-checkbox>
              </div>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "MenuList",
  props: {
    items: {
      required: true,
      type: Object | Array,
    },
    dataList: {
      required: true,
      type: Object | Array,
    },
    menusOrigianl: {
      required: true,
      type: Object | Array,
    },
  },
  data() {
    return {
      menuSelectedList: null,
      originalMenuIdList: this.menusOrigianl,
      isAll: true,
    };
  },
  created() {
    this.menuSelectedList = this.dataList;
  },
  updated() {
    if (this.menuSelectedList.length == this.menusOrigianl.length) {
      this.isAll = true;
    } else {
      this.isAll = false;
    }
  },
  watch: {
    menuSelectedList: function () {
      if (this.menuSelectedList.length == this.originalMenuIdList.length) {
        this.isAll = true;
      } else {
        this.isAll = false;
      }
    },
  },
  methods: {
    handleSelectAll(value) {
      Vue.nextTick(async () => {
        if (!value) {
          this.menuSelectedList = [];
        }

        this.$emit("handleSelectAll", value, this.menuSelectedList);
      });
    },
    addRootMenu: async function (rootList, rootId, rootIndex, sublist) {
      Vue.nextTick(async () => {
        this.$emit(
          "handleAddRootMenu",
          rootList,
          rootId,
          rootIndex,
          this.menuSelectedList,
          sublist
        );
      });
    },
    addSubMenu: async function (
      sublist,
      id,
      index,
      rootId,
      rootIndex,
      sublv2list
    ) {
      Vue.nextTick(async () => {
        this.$emit(
          "handleAddSubMenu",
          sublist,
          id,
          index,
          rootId,
          rootIndex,
          this.menuSelectedList,
          sublv2list
        );
      });
    },
    addSubMenuLevel2(
      rootId,
      mainId,
      subId,
      indexRoot,
      indexMain,
      sublv2list,
      sublist,
      rootlist
    ) {
      Vue.nextTick(async () => {
        this.$emit(
          "handleAddSubMenuLevel2",
          rootId,
          mainId,
          subId,
          indexRoot,
          indexMain,
          this.menuSelectedList,
          sublv2list,
          sublist,
          rootlist
        );
      });
    },
  },
};
</script>

<style scoped>
.parent-cat {
  display: inline-block;
  position: relative;
  top: 5px;
  margin-left: 10px;
}

.pl-lv4-cb {
  margin-left: 55px;
  margin-top: 22px;
}
</style>
<template>
  <div>
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold text-uppercase">Admin Details</h1>
          </b-col>
        </b-row>
        <b-row
          class="no-gutters bg-white-border mt-4"
          v-bind:class="$isLoading ? 'd-none' : 'd-block'"
        >
          <b-col class="px-4 px-sm-5 py-4">
            <HeaderLine text="Profile" class="mb-3" :isLine="false" />
            <b-row>
              <b-col md="6">
                <InputText
                  v-model="admin.user.email"
                  textFloat="Email"
                  placeholder="Email"
                  type="email"
                  name="email"
                  isRequired
                  :isValidate="$v.admin.user.email.$error"
                  :v="$v.admin.user.email"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <InputText
                  v-model="admin.user.firstName"
                  textFloat="First Name"
                  placeholder="First Name"
                  type="text"
                  name="firstName"
                  isRequired
                  :isValidate="$v.admin.user.firstName.$error"
                  :v="$v.admin.user.firstName"
                />
              </b-col>
              <b-col md="6">
                <InputText
                  v-model="admin.user.lastName"
                  textFloat="Last Name"
                  placeholder="Last Name"
                  type="text"
                  name="lastName"
                  isRequired
                  :isValidate="$v.admin.user.lastName.$error"
                  :v="$v.admin.user.lastName"
                />
              </b-col>
            </b-row>

            <!-- <b-row>
              <b-col md="6">
                <InputText
                  v-model="admin.user.password"
                  textFloat="Password"
                  placeholder="Password"
                  type="password"
                  name="password"
                  :isRequired="userId == 0 ? true : false"
                  :isValidate="$v.admin.user.password.$error"
                  :v="$v.admin.user.password"
                />
              </b-col>
              <b-col md="6">
                <InputText
                  v-model="admin.user.confirmPassword"
                  textFloat="Confirm Password"
                  placeholder="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  :isRequired="userId == 0 ? true : false"
                  :isValidate="$v.admin.user.confirmPassword.$error"
                  :v="$v.admin.user.confirmPassword"
                />
              </b-col>
            </b-row> -->

            <b-row>
              <b-col md="6">
                <label class="font-weight-bold main-label">
                  Status
                  <span class="text-danger">*</span>
                </label>
                <div>
                  <b-form-checkbox
                    switch
                    v-model="admin.user.status"
                    class="radio-active"
                    size="lg"
                  >
                    <span class="ml-2 main-label">{{
                      admin.user.status ? "Active" : "Inactive"
                    }}</span>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col>
                <label class="font-weight-bold main-label mb-2"
                  >Permission</label
                >
                <div class="d-inline-block" v-if="!admin.thisUser">
                  <span
                    class="text-secondary text-underline pl-3 pointer"
                    @click="showPermission = true"
                    v-if="!showPermission"
                    >Edit</span
                  >
                </div>
                <div>
                  <div v-if="isAll" class="font-weight-bold">All</div>
                  <div v-else>
                    <p
                      v-for="(item, key) in newMenus[0]"
                      :key="key"
                      class="mb-2 font-weight-bold"
                    >
                      {{ item.name }}
                      <template v-if="item.subMenu.length != 0">
                        <span
                          v-for="(sub, key2) in item.subMenu"
                          :key="key2"
                          class="d-block mt-2"
                        >
                          - {{ sub.name }}
                          <template v-if="sub.subMenu.length != 0">
                            <span class="nobreak font-weight-normal">
                              (
                              <span>{{ comma(sub.subMenu) }}</span>
                              )
                            </span>
                          </template>
                        </span>
                      </template>
                    </p>
                  </div>

                  <p class="text-danger" v-if="$v.admin.permissionId.$error">
                    Please select at least one permission
                  </p>
                </div>
              </b-col>
            </b-row>

            <div v-if="showPermission">
              <HeaderLine text="Permission" class="mt-4" />

              <div class="mb-4">
                <b-row>
                  <b-col>
                    <MenuList
                      :items="menus"
                      :dataList="admin.permissionId"
                      :menusOrigianl="menusOrigianl"
                      @handleAddRootMenu="addRootMenu"
                      @handleAddSubMenu="addSubMenu"
                      @handleAddSubMenuLevel2="addSubMenuLevel2"
                      @handleSelectAll="handleSelectAll"
                    />
                  </b-col>
                </b-row>
              </div>

              <div>
                <button
                  type="button"
                  class="btn btn-success btn-details-set text-uppercase"
                  @click="showPermission = false"
                >
                  Save
                </button>
              </div>
            </div>

            <div class="mt-5">
              <div class="d-block">
                <div role="tablist">
                  <b-card no-body class="tab-title-collapse">
                    <b-card-header
                      header-tag="header"
                      class="p-1 d-flex justify-content-between"
                      role="tab"
                    >
                      <b-button v-b-toggle.collapse-audit class="btn-collapse">
                        <font-awesome-icon icon="chevron-right" class="icon" />
                        <font-awesome-icon icon="chevron-down" class="icon" />
                        <span class="template-color-green">
                          Audit Log
                        </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      id="collapse-audit"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <div class="d-flex justify-content-end my-3">
                        <b-button
                          class="btn btn-main-2 mr-2 text-nowrap"
                          @click="exportData(1)"
                          >EXPORT EXCEL</b-button
                        >
                        <b-button
                          class="btn btn-main text-nowrap"
                          @click="exportData(2)"
                          >EXPORT CSV</b-button
                        >
                      </div>

                      <TableAudit
                        :items="items"
                        :fields="fields"
                        :isBusy="isBusy"
                        :filter="filter"
                        :rows="rows"
                        @handleChangePage="pagination"
                        :pageOptions="pageOptions"
                        @changePerPage="hanndleChangePerpage"
                      />
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>

            <!-- Button Cancel  Exit -->
            <b-row class="mt-5">
              <b-col md="6">
                <button
                  v-if="userId != 0"
                  type="button"
                  class="btn btn-danger btn-details-set mr-md-2 text-uppercase"
                  @click="openModalDelete(admin)"
                  :disabled="isDisable"
                >
                  Delete
                </button>

                <b-button
                  to="/admin"
                  class="btn-details-set"
                  :disabled="isDisable"
                  >CANCEL</b-button
                >
              </b-col>
              <b-col md="6" class="text-sm-right">
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(0)"
                  :disabled="isDisable"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(1)"
                  :disabled="isDisable"
                >
                  Save & Exit
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </form>
    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="isModalAlertConfirm"
      @confirm="btnDelete"
    />
    <b-modal ref="loadingModal" hide-header hide-footer centered>
      <div class="text-center">
        <img
          src="@/assets/images/icons/loading.svg"
          alt="loading"
          class="mb-3 w-25"
        />
        <h1 class="font-weight-bold text-modal">
          In progress. Exporting Data...
        </h1>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import HeaderLine from "@/components/HeaderLine";
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
  requiredIf,
} from "vuelidate/lib/validators";
import MenuList from "./components/MenuList";
import TableAudit from "@/views/pages/audit/components/TableAudit.vue";
export default {
  name: "AdminDetails",
  components: {
    HeaderLine,
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    MenuList,
    TableAudit,
  },
  data() {
    return {
      userId: this.$route.params.id,
      getDetailRequest: {
        userId: this.$route.params.id,
      },
      isDisable: false,
      isDisableBtnSaveExit: false,
      showPermission: false,
      isAll: false,
      statusActive: null,
      menus: [],
      newMenus: [],
      originalMenusName: [],
      menusOrigianl: [],
      menusSelected: [],
      parentList: [],
      arrayIdList: [],
      existId: "",
      admin: {
        user: {
          userId: null,
          email: null,
          firstName: null,
          lastName: null,
          // password: null,
          // confirmPassword: null,
          status: false,
        },
        permissionId: [],
      },
      modalMessage: "",
      requestDeleteAdmin: {
        id: null,
      },
      indexMain: 0,
      indexSub: 0,

      // audit
      fields: [
        // {
        //   key: "activityId",
        //   label: "Activity Id",
        //   class: "w-100px",
        // },
        {
          key: "timeStamp",
          label: "TimeStamp",
          class: "w-100px",
        },
        {
          key: "username",
          label: "Username",
          class: "w-100px",
        },
        {
          key: "page",
          label: "Page",
          class: "w-100px",
        },
        {
          key: "action",
          label: "Action",
          class: "w-100px",
        },
        // {
        //   key: "target",
        //   label: "Target",
        //   class: "w-100px",
        // },
        {
          key: "ipAddress",
          label: "IP Address",
          class: "w-100px",
        },

        {
          key: "detail",
          label: "",
          class: "w-100px",
        },
      ],
      items: [],
      isBusy: true,
      rows: 0,
      filter: {
        searchText: "",
        startFilterDate: "",
        endFilterDate: "",
        page: 1,
        pageSize: 10,
        pageName: [],
      },
      fileTypeId: 1,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  mounted: async function() {
    this.$isLoading = true;
    await this.getAdminDetail();
    await this.getListMenu();
    await this.getAuditList();
    this.$isLoading = false;
  },
  methods: {
    comma(array) {
      let str = "";
      for (let i = 0; i < array.length; i++) {
        str += array[i].name + " , ";
      }
      str = str.replace(/,\s*$/, "");
      return str;
    },
    getListMenu: async function() {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/menu/menuHierarchy`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.menus = data.detail;
        this.originalMenusName.push(data.detail);

        for (const data of this.menus) {
          this.menusOrigianl.push(data.id);
          if (data.subMenu.length != 0) {
            for (const sub of data.subMenu) {
              this.menusOrigianl.push(sub.id);
              if (sub.subMenu.length != 0) {
                for (const sub2 of sub.subMenu) {
                  this.menusOrigianl.push(sub2.id);
                }
              }
            }
          }
        }

        this.checkAll();
      }
    },
    getAdminDetail: async function() {
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/admin`,
        null,
        this.$headers,
        this.getDetailRequest
      );
      if (data.result == 1) {
        this.admin = data.detail;

        for (var i = 0; i < data.detail.permissionId.length; i++) {
          this.menusSelected.push(data.detail.permissionId[i]);
        }

        this.newMenus.push(data.detail.menuName);
      }
    },
    saveForm: async function(flag) {
      this.$v.admin.$touch();
      if (this.$v.admin.$error) return;
      this.isDisable = true;

      this.showPermission = false;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/admin/save`,
        null,
        this.$headers,
        this.admin
      );
      this.modalMessage = resData.message;
      this.isDisable = false;
      if (resData.result == 1) {
        this.existId = resData.detail.id;
        this.$refs.modalAlert.show();
        if (flag === 1) {
          setTimeout(this.routeToUserList, 2000);
        } else {
          if (this.userId != 0) {
            this.getDetailRequest.userId = this.existId;
            // this.getAdminDetail();
            // this.getListMenu();
          } else {
            this.userId = this.existId;
            this.admin.user.userId = this.existId;
            // this.admin.user.password = "";
            // this.admin.user.confirmPassword = "";
            this.$router.push({ path: `/admin/details/${this.existId}` });
          }
        }
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    openModalDelete(value) {
      //this.requestDeleteUser.userId = value.user.userId;
      this.modalMessage =
        "คุณต้องการลบ " +
        value.user.firstName +
        " " +
        value.user.lastName +
        " ใช่หรือไม่?";
      this.$refs.isModalAlertConfirm.show();
    },
    btnDelete: async function() {
      this.$refs.isModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/admin/remove`,
        null,
        this.$headers,
        this.getDetailRequest
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(this.routeToUserList, 2000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    routeToUserList() {
      this.$router.push("/admin");
    },
    handleSelectAll: async function(value, list) {
      //console.log( this.menusOrigianl)
      var notfound = -1;
      var menusSelected = this.menusSelected;

      this.$nextTick(async (index) => {
        if (value) {
          for (var i = 0; i < this.menusOrigianl.length; i++) {
            if (this.menusSelected.indexOf(this.menusOrigianl[i]) == notfound) {
              list.push(this.menusOrigianl[i]);
              this.menusSelected.push(this.menusOrigianl[i]);
            }
          }
        } else {
          list = [];
          this.menusSelected = [];
        }
        // console.log("handleSelectAll")
        // console.log(this.menusSelected)
        // console.log(list)

        this.checkAll();
        this.generateMenu();
      });
    },
    generateMenu: async function() {
      var listRemove = [];
      var notfound = -1;
      var fullmenu = JSON.parse(JSON.stringify(this.originalMenusName[0]));
      // this.newMenus[0].length = 0;
      if (this.menusSelected.length != 0) {
        this.$nextTick(async (index) => {
          this.newMenus[0].length = 0;
          // ตัด root ที่ไม่มีออก
          for (var i = 0; i < this.originalMenusName[0].length; i++) {
            if (this.menusSelected.indexOf(fullmenu[i].id) == notfound) {
              listRemove.push(i);
              //fullmenu.splice(i,1);
            }
          }

          for (var i = listRemove.length - 1; i >= 0; i--) {
            fullmenu.splice(listRemove[i], 1);
          }

          listRemove = [];

          // // ตัด sub ที่ไม่มีออก
          for (var i = 0; i < fullmenu.length; i++) {
            if (fullmenu[i].subMenu.length != 0) {
              for (var j = 0; j < fullmenu[i].subMenu.length; j++) {
                if (
                  this.menusSelected.indexOf(fullmenu[i].subMenu[j].id) ==
                  notfound
                ) {
                  var menu = {
                    parent: i,
                    child: j,
                  };
                  listRemove.push(menu);
                  //fullmenu[i].subMenu.splice(j,1);
                }
              }
            }
          }

          for (var i = listRemove.length - 1; i >= 0; i--) {
            fullmenu[listRemove[i].parent].subMenu.splice(
              listRemove[i].child,
              1
            );
          }

          listRemove = [];

          // ตัด sublv2 ที่ไม่มีออก
          for (var i = 0; i < fullmenu.length; i++) {
            if (fullmenu[i].subMenu.length != 0) {
              for (var j = 0; j < fullmenu[i].subMenu.length; j++) {
                if (fullmenu[i].subMenu[j].subMenu.length != 0) {
                  for (
                    var k = 0;
                    k < fullmenu[i].subMenu[j].subMenu.length;
                    k++
                  ) {
                    if (
                      this.menusSelected.indexOf(
                        fullmenu[i].subMenu[j].subMenu[k].id
                      ) == notfound
                    ) {
                      var menu = {
                        parent: i,
                        child: j,
                        childlv2: k,
                      };
                      listRemove.push(menu);
                      //fullmenu[i].subMenu[j].subMenu.splice(k,1);
                    }
                  }
                }
              }
            }
          }

          for (var i = listRemove.length - 1; i >= 0; i--) {
            fullmenu[listRemove[i].parent].subMenu[
              listRemove[i].child
            ].subMenu.splice(listRemove[i].childlv2, 1);
          }

          //console.log( this.newMenus[0] )

          //console.log(fullmenu.length)
          //add
          for (var i = 0; i < fullmenu.length; i++) {
            this.newMenus[0].push(fullmenu[i]);
          }
          this.newMenus[0].push(1);
          this.newMenus[0].splice(this.newMenus[0].length - 1, 1);
          //this.newMenus[0].push(fullmenu[i])

          this.admin.permissionId.length = 0;

          for (var i = 0; i < this.menusSelected.length; i++) {
            this.admin.permissionId.push(this.menusSelected[i]);
          }

          //console.log( this.newMenus[0] )
        });
      } else {
        this.$nextTick(async (index) => {
          for (var i = this.newMenus[0].length - 1; i >= 0; i--) {
            this.newMenus[0].splice(i, 1);
          }
        });
      }
    },
    checkAll: async function() {
      this.$nextTick(async (index) => {
        if (this.menusSelected.length == this.menusOrigianl.length) {
          //console.log("ALL")
          this.isAll = true;
        } else {
          //console.log("NOT ALL")
          this.isAll = false;
        }
      });
    },
    addRootMenu: async function(rootList, rootId, rootIndex, list, sublist) {
      var notfound = -1;
      var menusSelected = this.menusSelected;
      var admin = this.admin;
      var sublistId = sublist.map(function(x) {
        return x.id;
      });
      var arrayOfSublistlv2Id = [];

      for (var i = 0; i < sublist.length; i++) {
        arrayOfSublistlv2Id.push(
          sublist[i].subMenu.map(function(x) {
            return x.id;
          })
        );
      }

      var sublv2listId = [].concat.apply([], arrayOfSublistlv2Id);

      if (list.indexOf(rootId) != notfound) {
        list.splice(list.indexOf(rootId), 1);
      }

      this.$nextTick(async (index) => {
        if (this.menusSelected.indexOf(rootId) == notfound) {
          this.menusSelected.push(rootId);
          list.push(rootId);

          // เพิ่ม subId
          if (sublistId.length > 0) {
            for (var i = 0; i < sublistId.length; i++) {
              if (this.menusSelected.indexOf(sublistId[i]) == notfound) {
                this.menusSelected.push(sublistId[i]);
                list.push(sublistId[i]);
              }
            }
          }
          // เพิ่ม sublv2Id
          if (sublv2listId.length > 0) {
            for (var i = 0; i < sublv2listId.length; i++) {
              if (this.menusSelected.indexOf(sublv2listId[i]) == notfound) {
                this.menusSelected.push(sublv2listId[i]);
                list.push(sublv2listId[i]);
              }
            }
          }
        } else {
          this.menusSelected.splice(this.menusSelected.indexOf(rootId), 1);
          //list.splice(menusSelected.indexOf(rootId), 1)

          // เอา subId ออก
          if (sublistId.length > 0) {
            for (var i = 0; i < sublistId.length; i++) {
              if (this.menusSelected.indexOf(sublistId[i]) != notfound) {
                this.menusSelected.splice(
                  this.menusSelected.indexOf(sublistId[i]),
                  1
                );
                list.splice(list.indexOf(sublistId[i]), 1);
              }
            }
          }
          // เอา sublv2Id ออก
          if (sublv2listId.length > 0) {
            for (var i = 0; i < sublv2listId.length; i++) {
              if (this.menusSelected.indexOf(sublv2listId[i]) != notfound) {
                this.menusSelected.splice(
                  this.menusSelected.indexOf(sublv2listId[i]),
                  1
                );
                list.splice(list.indexOf(sublv2listId[i]), 1);
              }
            }
          }
        }

        // console.log("addRootMenu")
        // console.log(this.menusSelected)
        // console.log(list)

        if (list.length != this.menusSelected.length) {
          //console.log("-----------Not Equal------------")
        }

        this.generateMenu();
        this.checkAll();
      });
    },
    addSubMenu: async function(
      sublist,
      subId,
      index,
      rootId,
      rootIndex,
      list,
      sublv2list
    ) {
      var notfound = -1;
      var menusSelected = this.menusSelected;
      var admin = this.admin;
      //ดึงไอดีของเพื่อนที่อยู่ใน sublist เดียวกันขึ้นมา
      var sublistId = sublist.map(function(x) {
        return x.id;
      });
      var sublv2listId = sublv2list.map(function(x) {
        return x.id;
      });

      if (list.indexOf(subId) != notfound) {
        list.splice(list.indexOf(subId), 1);
      }

      //console.log(list)

      this.$nextTick(async (index) => {
        //เชคว่ามี submenu นี้รึยัง
        if (this.menusSelected.indexOf(subId) == notfound) {
          //เชคว่ามี root ของ submenu นี้รึยัง
          if (this.menusSelected.indexOf(rootId) == notfound) {
            this.menusSelected.push(rootId);
            list.push(rootId);
          }

          this.menusSelected.push(subId);
          list.push(subId);
          // ถ้ามี sublv2
          if (sublv2listId.length > 0) {
            for (var i = 0; i < sublv2listId.length; i++) {
              if (this.menusSelected.indexOf(sublv2listId[i]) == notfound) {
                this.menusSelected.push(sublv2listId[i]);
                list.push(sublv2listId[i]);
              }
            }
          }
        } else {
          //เอา submenu นี้ออก
          this.menusSelected.splice(this.menusSelected.indexOf(subId), 1);
          //list.splice(list.indexOf(subId), 1)

          //เอาไว้เชควว่าเพื่อนๆหมดรึยังถ้าหมดก็เอา rootId ออก นึก linq ไม่ออกเขียน for ไว้ก่อน
          var HaveSameSublist = false;
          for (var i = 0; i < sublistId.length; i++) {
            if (this.menusSelected.indexOf(sublistId[i]) != notfound) {
              HaveSameSublist = true;
            }
          }

          if (!HaveSameSublist) {
            //เอา root ของ submenu นี้ออก

            this.menusSelected.splice(this.menusSelected.indexOf(rootId), 1);
            list.splice(list.indexOf(rootId), 1);
          }

          // ถ้ามี sublv2 จะต้องนำออก
          if (sublv2listId.length > 0) {
            for (var i = 0; i < sublv2listId.length; i++) {
              if (this.menusSelected.indexOf(sublv2listId[i]) != notfound) {
                this.menusSelected.splice(
                  this.menusSelected.indexOf(sublv2listId[i]),
                  1
                );
                list.splice(list.indexOf(sublv2listId[i]), 1);
              }
            }
          }
        }

        // console.log("addSubMenu")
        // console.log(this.menusSelected)
        // console.log(list)

        if (list.length != this.menusSelected.length) {
          //console.log("-----------Not Equal------------")
        }

        this.generateMenu();
        this.checkAll();
      });
    },
    addSubMenuLevel2: async function(
      rootId,
      subId,
      sublv2Id,
      indexRoot,
      indexMain,
      list,
      sublv2list,
      sublist,
      rootlist
    ) {
      var notfound = -1;
      var menusSelected = this.menusSelected;
      var sublistId = sublist.map(function(x) {
        return x.id;
      });
      var sublv2listId = sublv2list.map(function(x) {
        return x.id;
      });
      var rootlistId = rootlist.map(function(x) {
        return x.id;
      });

      if (list.indexOf(sublv2Id) != notfound) {
        list.splice(list.indexOf(sublv2Id), 1);
      }

      this.$nextTick(async (index) => {
        //เชคว่า sublv2Id มีไหม
        if (this.menusSelected.indexOf(sublv2Id) == notfound) {
          //เชคว่า rootId มีไหม
          if (this.menusSelected.indexOf(rootId) == notfound) {
            this.menusSelected.push(rootId);
            list.push(rootId);
          }
          //เชคว่า subId มีไหม
          if (this.menusSelected.indexOf(subId) == notfound) {
            this.menusSelected.push(subId);
            list.push(subId);
          }
          this.menusSelected.push(sublv2Id);
          list.push(sublv2Id);
        } else {
          //เอา sub lv 2 ออก
          this.menusSelected.splice(this.menusSelected.indexOf(sublv2Id), 1);

          //เชคว่าเพื่อนๆ lv2 หมดรึยัง

          var HaveSameSublv2list = false;
          for (var i = 0; i < sublv2listId.length; i++) {
            if (this.menusSelected.indexOf(sublv2listId[i]) != notfound) {
              HaveSameSublv2list = true;
            }
          }

          if (!HaveSameSublv2list) {
            //เอา root ของ submenu นี้ออก
            this.menusSelected.splice(this.menusSelected.indexOf(subId), 1);
            list.splice(list.indexOf(subId), 1);
          }

          //เชคว่าเพื่อนๆของ subId หมดรึยัง
          var HaveSameSublist = false;
          for (var i = 0; i < sublistId.length; i++) {
            if (this.menusSelected.indexOf(sublistId[i]) != notfound) {
              HaveSameSublist = true;
            }
          }
          if (!HaveSameSublist) {
            //เอา root ของ submenu นี้ออก
            this.menusSelected.splice(this.menusSelected.indexOf(rootId), 1);
            list.splice(list.indexOf(rootId), 1);
          }
        }
        // console.log("addSubMenuLevel2")
        // console.log(this.menusSelected)
        // console.log(list)
        if (list.length != this.menusSelected.length) {
          //console.log("-----------Not Equal------------")
        }
        this.generateMenu();
        this.checkAll();
      });
    },
    exportData: async function(flagFileType) {
      this.$refs.loadingModal.show();
      let fileType = flagFileType === 2 ? ".csv" : ".xlsx";
      this.fileTypeId = flagFileType;

      axios({
        url: `${this.$baseUrl}/api/auditlog/export`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: {
          fileTypeId: this.fileTypeId,
          startFilterDate: this.filter.startFilterDate,
          endFilterDate: this.filter.endFilterDate,
          page: this.filter.page,
          pageSize: this.filter.pageSize,
          searchText: this.filter.searchText,
          pageName: this.filter.pageName,
        },
      })
        .then((response) => {
          console.log(response);
          this.$refs.loadingModal.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateFile = this.$moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Report ` + "Audit" + `-` + dateFile + fileType
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          this.$refs.loadingModal.hide();
          this.modalMessage = "ไม่สามารถ Export ข้อมูลได้";
          this.$refs.modalAlertError.show();
        });
    },
    getAuditList: async function() {
      this.items = [];
      this.isBusy = true;
      this.filter.searchText = this.admin.user.email;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/auditlog/AllList`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.data;
        this.rows = resData.detail.totalData;
        this.isBusy = false;
      }
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getAuditList();
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.pageSize = value;
      this.getAuditList();
    },
  },
  validations: {
    admin: {
      user: {
        firstName: {
          required,
          maxLength: maxLength(50),
        },
        lastName: {
          required,
          maxLength: maxLength(50),
        },
        email: {
          required,
          email,
        },
        // password: {
        //   required: requiredIf(function() {
        //     return this.userId == 0;
        //   }),
        //   minLength: minLength(6),
        // },
        // confirmPassword: {
        //   required: requiredIf(function() {
        //     return this.userId == 0;
        //   }),
        //   minLength: minLength(6),
        //   sameAsPassword: sameAs("password"),
        // },
      },
      permissionId: {
        required: requiredIf(function() {
          return this.admin.permissionId.length == 0;
        }),
      },
    },
  },
};
</script>
<style scoped>
.tab-title-collapse {
  border: none;
}
.btn-collapse {
  border-radius: 0;
  background-color: white !important;
  font-size: 17px;
  padding: 0;
  border: 0;
}
.collapsed > .fa-chevron-down,
:not(.collapsed) > .fa-chevron-right {
  display: none;
}
</style>
